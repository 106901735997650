@import 'src/styles/mixins/button';
@import 'src/styles/vars';
@import 'src/styles/layout.module.scss';

$componentBottomPadding: 24px;
$containerHeight: $searchBarHeight + $componentBottomPadding;

:export {
    containerHeight: $containerHeight;
}


.container {
    align-items: center;
    display: flex;
    height: $containerHeight;
    width: 100%;

    .searchbar {
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 380px;
        }

        .searchbarInputWrapper {
            width: 100%;
            padding: 12px 16px;
            height: $containerHeight;

            .searchbarInput {
                background-color: $color-black-alpha-5;
                border-radius: 50px;

                height: $searchBarHeight;

                .startAdornment {
                    margin: 0 18px;

                    button {
                        @include unbuttonize();
                    }
                }
            }
        }


    }
}

.noPopper {
    display: none;
}

.isFirstElement {
    padding-top: $componentBottomPadding / 2;
}

.hidden {
    overflow: hidden;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function, visibility 0s $mobile-header-collapse-transition-time;
}

.visible {
    opacity: 1;
    height: $containerHeight;
    visibility: visible;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function;
}
