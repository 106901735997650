@import 'src/styles/mixins/button';
@import 'src/styles/vars';
@import 'src/styles/layout.module.scss';

$componentBottomPadding: 16px;
$containerHeight: $searchBarHeight + $componentBottomPadding;

:export {
    containerHeight: $containerHeight;
}


.container {
    background-color: $color-oatmeal;
    height: $containerHeight;
    width: 100%;

    .statusContainer {
        align-items: center;
        box-shadow: 0px 4px 12px -1px rgba(0, 0, 0, 0.1);
        display: flex;
        flex: 1;
        flex-direction: column;
        height: auto;
        justify-content: center;
        min-height: 80px;
        width: 100%;
    }

    .dropdown {
        background-color: $color-oatmeal;
        display: flex;
        flex-direction: column;
        left: 0;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        z-index: 10;
    }
}

.isFirstElement {
    padding-top: $componentBottomPadding / 2;
}

.hidden {
    overflow: hidden;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function, visibility 0s $mobile-header-collapse-transition-time;
}

.visible {
    padding-bottom: $componentBottomPadding;

    opacity: 1;
    height: $containerHeight;
    visibility: visible;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function;
}
