@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import 'colors.module';
@import 'onboarding.module';

$primary: #000;
$background: #EEE1D0;
$background-content: #EAE0D0;
$theme-colors: (
    'primary': $primary,
);

// TODO improve naming
// Should this merge with color-blueberry
$onboarding-notification-background-color: #8E97AE;

// TODO: we should standardize breakpoints
$breakpoint-mobile: 768px;
$min-page-width: 320px;
$sidebar-width: 300px;



$zindex-snackbar: 1400;
$zindex-modal: 1300;
$zindex-drawer: 1200;
$zindex-product-sheet-backdrop: 9000;
$zindex-product-sheet: 9001;

// Animation bezier curves (from https://easings.net/)
$easing-easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
$easing-easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easing-easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);

$mobile-header-easing-function: $easing-easeInOutCubic;
$mobile-navbar-height: 72px;
$mobile-header-collapse-transition-time: 500ms;

// Customize Bootstrap variables after importing the defaults
$enable-rounded: false;
$border-color: #000000;
$border-radius: 8px;
$table-border-color: #000;
$input-border-width: 1px;
$input-border-color: #000;
$input-color: #000;
$input-bg: transparent;
$input-focus-bg: rgba(#fff, .7);
$input-disabled-bg: rgba(#000, .25);

$primary: #000;

// See also src/constants/gridBreakpoints.ts
$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 900px,
    lg: 1200px,
    xl: 1536px,
    xxl: 1740px
);