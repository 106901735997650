@import 'src/styles/vars';
@import 'src/styles/mixins/typography';

.header {
    width: 100%; 

    &.navigation {
        grid-template-columns: 24px 1fr 24px;
    }
    &.title {
        grid-template-columns: 1fr 24px;
    }

    &.navigation,
    &.title {
        display: grid;
        grid-column-gap: 24px;
        padding: 0;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr;
            & {
                .headerIcon {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 10px;
    }

}
.headerLink {
    margin-right: 24px;
}
.headerText {
    @include font-family-helvetica-neue;

    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.4;

    &.title {
        text-align: left;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 25px;
    }
}

.headerAction {
    cursor: pointer;
}
