@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

$gap-8: 8px;
$gap-12: 12px;
$gap-16: 16px;
$gap-20: 20px;
$gap-24: 24px;
$gap-28: 28px;
$gap-32: 32px;
$gap-36: 36px;
$gap-40: 40px;
$gap-48: 48px;
$gap-56: 56px;
$gap-64: 64px;
$gap-72: 72px;
$gap-80: 80px;
$gap-84: 84px;
$gap-88: 88px;
$gap-92: 92px;
$gap-96: 96px;
$gap-108: 108px;
$gap-112: 112px;
$gap-116: 116px;
$gap-120: 120px;
$gap-160: 160px;

.responsiveVerticalSpacer0x64 {
    height: 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-64;
    }
}

.responsiveVerticalSpacer8x24 {
    height: $gap-8;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-24;
    }
}

.responsiveVerticalSpacer8x32 {
    height: $gap-8;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-32;
    }
}


.responsiveVerticalSpacer16x24 {
    height: $gap-16;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-24;
    }
}

.responsiveVerticalSpacer16x32 {
    height: $gap-16;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-32;
    }
}

.responsiveVerticalSpacer16x40 {
    height: $gap-16;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-40;
    }
}

.responsiveVerticalSpacer20x24 {
    height: $gap-20;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-24;
    }
}

.responsiveVerticalSpacer24x8 {
    height: $gap-24;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-8;
    }
}

.responsiveVerticalSpacer24x16 {
    height: $gap-24;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-16;
    }
}

.responsiveVerticalSpacer24x32 {
    height: $gap-24;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-32;
    }
}

.responsiveVerticalSpacer24x40 {
    height: $gap-24;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-40;
    }
}

.responsiveVerticalSpacer32x40 {
    height: $gap-32;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-40;
    }
}

.responsiveVerticalSpacer32x80 {
    height: $gap-32;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-80;
    }
}

.responsiveVerticalSpacer40x80 {
    height: $gap-40;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: $gap-80;
    }
}

.spacer8 {
    width: 100%;
    height: $gap-8;
}

.spacer12 {
    width: 100%;
    height: $gap-12;
}

.spacer16 {
    width: 100%;
    height: $gap-16;
}

.spacer20 {
    width: 100%;
    height: $gap-20;
}

.spacer24 {
    width: 100%;
    height: $gap-24;
}

.spacer28 {
    width: 100%;
    height: $gap-28;
}

.spacer32 {
    width: 100%;
    height: $gap-32;
}

.spacer36 {
    width: 100%;
    height: $gap-36;
}

.spacer40 {
    width: 100%;
    height: $gap-40;
}

.spacer48 {
    width: 100%;
    height: $gap-48;
}

.spacer56 {
    width: 100%;
    height: $gap-56;
}

.spacer64 {
    width: 100%;
    height: $gap-64;
}

.spacer72 {
    width: 100%;
    height: $gap-72;
}

.spacer80 {
    width: 100%;
    height: $gap-80;
}

.spacer84 {
    width: 100%;
    height: $gap-84;
}

.spacer88 {
    width: 100%;
    height: $gap-88;
}

.spacer92 {
    width: 100%;
    height: $gap-92;
}

.spacer96 {
    width: 100%;
    height: $gap-96;
}

.spacer108 {
    width: 100%;
    height: $gap-108;
}

.spacer112 {
    width: 100%;
    height: $gap-112;
}

.spacer116 {
    width: 100%;
    height: $gap-116;
}

.spacer120 {
    width: 100%;
    height: $gap-120;
}

.spacer160 {
    width: 100%;
    height: $gap-160;
}

.horizontalPadding20 {
    padding: 0 $gap-20
}