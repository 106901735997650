@import 'src/styles/colors.module.scss';
@import 'src/styles/layout.module.scss';

$containerHeight: $searchBarHeight;

.searchInputContainer {
    align-items: center;
    background-color: $color-black-alpha-5;
    border-radius: 52px;
    display: flex;
    height: $searchBarHeight;
    margin: 0 20px;
    padding: 0 12px;

    .searchInput {
        background-color: transparent;
        border: none;
        height: 100%;
        outline: none;
        width: 100%;
        padding-left: 14px;
    }

    .searchInputIconContainer {
        align-items: center;
        display: flex;
        height: $containerHeight;
        justify-content: center;
        width: 30px;

        &.clickable {
            cursor: pointer;
        }
    }
}