@import 'src/styles/vars';
@import 'src/styles/typography.module.scss';

.notification {
    max-width: 80%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
}

.notification__variant_info {
    background-color: $color-vanilla;
}

.notification__body {
    @extend .body2bold;
    display: flex;
    flex-direction: row;
    gap: 16px;

    button {
        color: $color-mint;
        @extend .body2bold;
    }
}