@import 'src/styles/mixins/button';
@import 'src/styles/vars';


.searchResultsBackground {
  background: $color-oatmeal;
  border-radius: 0;
  box-shadow: 0px 4px 12px -8px rgba(0, 0, 0, 0.2);
  margin: 0 -20px;
  padding: 0 24px;

  li:not(:first-child) {
    margin-top: 2px;
  }

  li:not(:last-child) {
    overflow: visible;
    margin-bottom: 2px;
  }

  li:last-child .dividerImage {
    display: none;
  }
}