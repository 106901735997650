@import 'src/styles/colors.module.scss';
@import 'src/styles/mixins/typography';

$header-height: 64px;

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - $header-height);
  position: relative;
  width: 100%;
}

.container,
.contentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.contentContainer {
  margin-top: 36px;
}

.header {
  align-items: center;
  border-bottom: 1px solid $color-black;
  display: flex;
  height: $header-height;
  justify-content: center;
}

.logo {
  height: 21px;
}

.title {
  color: $color-black;
  text-align: center;
  font-family: 'Run Wild';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  margin-bottom: 64px;
}

.message {
  @include font-family-helvetica-neue;
  
  color: $color-black;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 72px;
}

.illustration {
  margin-bottom: 36px;
  max-width: 100%;
  display: block;
}

.cta {
  width: 100%;
}

@media (max-width: 600px) {
  .contentContainer {
    padding: 0 54px;
    margin-bottom: 50px; // button size
  }

  .cta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
}
