@import 'src/styles/vars';

.button {
  position: relative;
}

.text {
  color: $color-black;
  left: 0;
  position: absolute;
  text-align: center;
  top: 8px; // Manually position inside the icon
  width: 32px;
}
