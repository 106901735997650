// Colors to be DEPRECATED
$color-cream: #ede1d1; // TODO: Potentially retire this color
// ENDS DEPRECATED COLORS


$color-black: #000000;
$color-black-alpha-5: rgba(0, 0, 0, 0.05);
$color-black-alpha-10: rgba(0, 0, 0, 0.1);
$color-black-alpha-15: rgba(0, 0, 0, 0.15);
$color-black-alpha-20: rgba(0, 0, 0, 0.2);
$color-black-alpha-30: rgba(0, 0, 0, 0.3);
$color-black-alpha-40: rgba(0, 0, 0, 0.4);
$color-black-alpha-50: rgba(0, 0, 0, 0.5);
$color-black-alpha-60: rgba(0, 0, 0, 0.6);
$color-black-alpha-70: rgba(0, 0, 0, 0.7);
$color-black-alpha-80: rgba(0, 0, 0, 0.8);


$color-blueberry-shade: #9FAABB;
$color-blueberry-tint: #bdc4cb;
$color-blueberry: #8794aa;
$color-cayenne: #D6B394;
$color-cayenne-tint: #E1C8AE;
$color-cayenne-shade: #702D00;
$color-coffee: #947f5a;
$color-kale: #7e7d5a;
$color-lemon: #ECD391;
$color-matcha: #c2c282;
$color-matcha-50: rgba(194, 194, 130, 0.5);
$color-matcha-tint: #DAD4B1;
$color-mint: #507600;
$color-oatmeal-tint: #d7cebf;
$color-oatmeal: #eae0d0;
$color-peach-tint: #ddc5ad;
$color-peach: #d18959;
$color-radish: #a85822;
$color-salt: #bdc4cb;
$color-tangerine-shade: #c89619;
$color-tangerine-tint: #f3c95e;
$color-tangerine: #e1aa1e;
$color-vanilla: #f2ede1;
$color-white: #ffffff;
// Variations of black colors
$color-black-5: #ded5c6;
$color-black-10: #d3cabb;
$color-black-20: #bbb3a6;
$color-black-40: #8c867d;
$color-black-60: #5e5a53;

$color-graphite: $color-black-5;

$color-error-1: $color-peach-tint;
$color-error-2: $color-peach;
$color-error-3: $color-radish;

$color-success-1: $color-matcha-tint;
$color-success-2: $color-matcha;
$color-success-3: $color-kale;

$color-cta-1: $color-tangerine-tint;
$color-cta-1: $color-tangerine;
$color-cta-1: $color-tangerine-shade;


.bgBlueberry {
    background-color: $color-blueberry;
}

.bgCoffee {
    background-color: $color-coffee;
}

.bgKale {
    background-color: $color-kale;
}

.bgMatcha {
    background-color: $color-matcha;
}

.bgOatmeal {
    background-color: $color-oatmeal;
}

.bgPeach {
    background-color: $color-peach;
}

.bgRadish {
    background-color: $color-radish;
}

.bgSalt {
    background-color: $color-salt;
}

.bgTangerine {
    background-color: $color-tangerine;
}

.bgVanilla {
    background-color: $color-vanilla;
}

.bgWhite {
    background-color: $color-white;
}

.colorBlack {
    color: $color-black;
}

.colorBlack60 {
    color: $color-black-60;
}

.colorBlackAlpha40 {
    color: $color-black-alpha-40;
}

.colorBlackAlpha60 {
    color: $color-black-alpha-60;
}

.colorBlackAlpha70 {
    color: $color-black-alpha-70;
}

.opacity50percent {
    opacity: 0.5;
}

:export {
    BLACK: $color-black;
    BLUEBERRY: $color-blueberry;
    CAYENNE: $color-cayenne;
    CAYENNE_SHADE: $color-cayenne-shade;
    CAYENNE_TINT: $color-cayenne-tint;
    COFFEE: $color-coffee;
    GRAPHITE: $color-black-5;
    KALE: $color-kale;
    LEMON: $color-lemon;
    MATCHA: $color-matcha;
    MINT: $color-mint;
    OATMEAL: $color-oatmeal;
    PEACH: $color-peach;
    PEACH_TINT: $color-peach-tint;
    RADISH: $color-radish;
    SALT: $color-salt;
    TANGERINE: $color-tangerine;
    VANILLA: $color-vanilla;
    WHITE: $color-white;
}