@import 'src/styles/colors.module.scss';


.option {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 48px;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;

    .label {
        width: 100%;
    }
}

.resultItem {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 48px;
    min-height: 48px;
    padding: 0 16px;
    width: 100%;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: $color-black-alpha-5; // Light beige hover background
    }
}