@import 'src/styles/colors.module.scss';
@import 'src/styles/vars';

$componentHeight: 37px;
$componentBottomPadding: 16px;
$containerHeight: $componentHeight + $componentBottomPadding;

:export {
    containerHeight: $containerHeight;
}

.container {
    width: 100%;
    background-color: $color-oatmeal;
}

.item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    margin-bottom: 4px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background-color: $color-oatmeal-tint;
    }

    &[data-category-active="true"] {
        background-color: $color-tangerine;
        box-shadow: 0 2px 4px $color-black-alpha-15;
    }
}

.itemText {
    color: $color-black;
}

.itemTextIdle {
    color: $color-black-alpha-40;
}

.hidden {
    overflow: hidden;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function, visibility 0s $mobile-header-collapse-transition-time;
}

.visible {
    padding-bottom: $componentBottomPadding;
    opacity: 1;
    height: $containerHeight;
    visibility: visible;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function;
}