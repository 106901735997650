@import 'src/styles/colors.module.scss';
@import 'src/styles/typography.module.scss';

.button {
    -webkit-font-smoothing: antialiased;
    align-items: center;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    display: flex;
    font-family: $helvetica-neue;
    font-weight: 600;
    font-size: 16px;
    height: 56px;
    justify-content: center;
    outline: none;
    width: 100%;

    @include media-breakpoint-up(sm) {
        height: 64px;
    }

    &.matchInputHeight {
        height: 44px;
    }

    &.squaredRight,
    &.squaredBoth {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.squaredLeft,
    &.squaredBoth {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.loading {
        cursor: progress;
    }

    &.uppercase {
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    &.tangerine {
        background-color: $color-tangerine;
        color: $color-black;

        &:hover {
            background-color: $color-tangerine-shade;
            color: $color-black;
        }

        &:focus {
            background-color: $color-tangerine-shade;
            border: 1px solid $color-black;
            color: $color-black;
        }

        &:disabled {
            background-color: $color-black-alpha-10;
            color: $color-black-alpha-30;
        }

        &.loading {
            background-color: $color-tangerine-tint;
            color: $color-black-alpha-50;
        }
    }

    &.charcoal {
        background-color: $color-black;
        color: $color-oatmeal;

        &:hover {
            background-color: $color-black-alpha-70;
            color: $color-oatmeal;
        }

        &:focus {
            background-color: $color-black-alpha-80;
            color: $color-oatmeal;
        }

        &:disabled {
            background-color: $color-black-alpha-10;
            color: $color-black-alpha-30;
        }

        &.loading {
            background-color: $color-black-alpha-50;
            color: $color-black-alpha-50;
        }
    }

    &.blueberry {
        background-color: $color-blueberry;
        color: $color-black;

        &:hover {
            background-color: $color-blueberry-shade;
            color: $color-black;
        }

        &:focus {
            background-color: $color-blueberry-shade;
            border: 1px solid $color-black;
            color: $color-black;
        }

        &:disabled {
            background-color: $color-black-alpha-10;
            color: $color-black-alpha-30;
        }

        &.loading {
            background-color: $color-blueberry-tint;
            color: $color-black-alpha-50;
        }
    }

    &.outlined {
        background-color: transparent;
        color: $color-black-60;
        border: 1px solid $color-black-40 !important;

        &:hover {
            background-color: $color-black-10;
        }

        &:focus {
            background-color: $color-black-20;
        }

        &:disabled {
            background-color: $color-black-alpha-10;
            color: $color-black-alpha-30;
            border: none !important;
        }

        &.loading {
            background-color: transparent;
            color: $color-black-alpha-50;
            border: 1px solid $color-black-40 !important;
        }
    }

    &.ghost {
        background-color: transparent;
        color: $color-black-60;

        &:hover {
            background-color: $color-black-10;
        }

        &:focus {
            background-color: $color-black-20;
        }

        &:disabled {
            background-color: $color-black-alpha-10;
            color: $color-black-alpha-30;
            border: none !important;
        }

        &.loading {
            background-color: transparent;
            color: $color-black-alpha-50;
            border: 1px solid $color-black-40 !important;
        }
    }
}

.container {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    animation: spinner 1s linear infinite;
    left: 16px;
    position: absolute;
}