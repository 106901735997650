// For hiding elements visually, without impacting their discoverability to screen readers
// https://www.a11yproject.com/posts/how-to-hide-content/
@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}