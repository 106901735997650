@import 'src/styles/colors.module.scss';

$borderRadius: 8px;
$gridGap: 8px;

.searchPromptsContainer {
    border-radius: $borderRadius;
    display: flex;
    flex-wrap: wrap;
    gap: $gridGap;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    background-color: $color-oatmeal;

    .searchPrompt {
        align-items: center;
        background-color: $color-black-alpha-5;
        border-radius: $borderRadius;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex: 1 1 calc(50% - $gridGap);
        height: 60px;
        justify-content: space-between;
        padding: 0 20px;
        width: 100%;
    }
}