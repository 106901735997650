@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import 'src/styles/colors.module.scss';
@import 'src/styles/mixins/typography';

$helvetica-neue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$run-wild: "Run Wild", serif;

@mixin font-family-helvetica-neue {
    font-family: $helvetica-neue;
}

@mixin font-family-run-wild {
    font-family: $run-wild;
}

@mixin regular-weight {
    font-weight: 400;
}

@mixin medium-weight {
    font-weight: 500;
}

@mixin bold-weight {
    font-weight: 700;
}

@mixin uppercase {
    text-transform: uppercase;
}

@mixin link {
    cursor: pointer;
    text-decoration: underline;
}

// Title

@mixin title {
    @include font-family-helvetica-neue;
    @include uppercase;
    @include bold-weight
}

@mixin title-1-mobile {
    @include title;

    font-size: 40px;
    letter-spacing: -0.4px;
    line-height: 95%;
}

@mixin title-1-desktop {
    @include title;

    font-size: 60px;
    letter-spacing: -0.6px;
    line-height: 95%;
}

@mixin title-2-mobile {
    @include title;

    font-size: 32px;
}

@mixin title-2-desktop {
    @include title;

    font-size: 40px;
}

@mixin title-3-mobile {
    @include title;

    font-size: 24px;
}

@mixin title-3-desktop {
    @include title;

    font-size: 30px;
}

@mixin title-4-mobile {
    @include title;

    font-size: 14px;
    letter-spacing: 0.28px;
}

@mixin title-4-desktop {
    @include title;

    font-size: 16px;
    letter-spacing: 0.32px;
}

@mixin title-5-mobile {
    @include title;

    font-size: 12px;
}

@mixin title-5-desktop {
    @include title;

    font-size: 14px;
}

.title1 {
    @include title-1-mobile;

    @include media-breakpoint-up(sm) {
        @include title-1-desktop;
    }
}

.title2 {
    @include title-2-mobile;

    @include media-breakpoint-up(sm) {
        @include title-2-desktop;
    }
}

.title3 {
    @include title-3-mobile;

    @include media-breakpoint-up(sm) {
        @include title-3-desktop;
    }
}

.title4 {
    @include title-4-mobile;

    @include media-breakpoint-up(sm) {
        @include title-4-desktop;
    }
}

.title5 {
    @include title-5-mobile;

    @include media-breakpoint-up(sm) {
        @include title-5-desktop;
    }
}

// Headline

@mixin headline {
    @include font-family-helvetica-neue;
    @include bold-weight;
}

@mixin headline-1-mobile {
    @include headline;

    font-size: 40px;
    line-height: 110%;
    letter-spacing: -0.4px;
}

@mixin headline-1-desktop {
    @include headline;

    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.6px;
}

@mixin headline-2-mobile {
    @include headline;

    font-size: 32px;
    line-height: 110%;
}

@mixin headline-2-desktop {
    @include headline;

    font-family: "Helvetica Neue";
    font-size: 44px;
    line-height: 110%;
}

@mixin headline-3-mobile {
    @include headline;

    font-size: 24px;
    line-height: 120%;
}

@mixin headline-3-desktop {
    @include headline;

    font-size: 32px;
    line-height: 120%;
}

@mixin headline-4-mobile {
    @include headline;

    font-size: 18px;
    line-height: 120%;
}

@mixin headline-4-desktop {
    @include headline;

    font-size: 24px;
    line-height: 120%;
}

@mixin headline-5-mobile {
    @include headline;

    font-size: 14px;
    line-height: 120%;
}

@mixin headline-5-desktop {
    @include headline;

    font-size: 16px;
    line-height: 120%;
}

.headline1 {
    @include headline-1-mobile;

    @include media-breakpoint-up(sm) {
        @include headline-1-desktop;
    }
}

.headline2 {
    @include headline-2-mobile;

    @include media-breakpoint-up(sm) {
        @include headline-2-desktop;
    }
}

.headline3 {
    @include headline-3-mobile;

    @include media-breakpoint-up(sm) {
        @include headline-3-desktop;
    }
}

.headline4 {
    @include headline-4-mobile;

    @include media-breakpoint-up(sm) {
        @include headline-4-desktop;
    }
}

.headline5 {
    @include headline-5-mobile;

    @include media-breakpoint-up(sm) {
        @include headline-5-desktop;
    }
}

// Body

@mixin body {
    @include font-family-helvetica-neue;
}

@mixin body-mobile {
    @include body;
    line-height: 150%;
}

@mixin body-desktop {
    @include body;
    line-height: 150%;
}

@mixin body-1-mobile {
    @include body-mobile;

    font-size: 16px;
}


@mixin body-1-desktop {
    @include body-desktop;

    font-size: 18px;
}

@mixin body-2-mobile {
    @include body-mobile;

    font-size: 14px;
}


@mixin body-2-desktop {
    @include body-desktop;

    font-size: 16px;
}

@mixin body-3-mobile {
    @include body-mobile;

    font-size: 12px;
}


@mixin body-3-desktop {
    @include body-desktop;

    font-size: 14px;
}

.body1regular {
    @include regular-weight;
    @include body-1-mobile;

    @include media-breakpoint-up(sm) {
        @include body-1-desktop;
    }
}

.body2regular {
    @include regular-weight;
    @include body-2-mobile;

    @include media-breakpoint-up(sm) {
        @include body-2-desktop;
    }
}

.body3regular {
    @include regular-weight;
    @include body-3-mobile;

    @include media-breakpoint-up(sm) {
        @include body-3-desktop;
    }
}

.body1medium {
    @include medium-weight;
    @include body-1-mobile;

    @include media-breakpoint-up(sm) {
        @include body-1-desktop;
    }
}

.body2medium {
    @include medium-weight;
    @include body-2-mobile;

    @include media-breakpoint-up(sm) {
        @include body-2-desktop;
    }
}

.body3medium {
    @include medium-weight;
    @include body-3-mobile;

    @include media-breakpoint-up(sm) {
        @include body-3-desktop;
    }
}

.body1bold {
    @include bold-weight;
    @include body-1-mobile;

    @include media-breakpoint-up(sm) {
        @include body-1-desktop;
    }
}

.body2bold {
    @include bold-weight;
    @include body-2-mobile;

    @include media-breakpoint-up(sm) {
        @include body-2-desktop;
    }
}

.body3bold {
    @include bold-weight;
    @include body-3-mobile;

    @include media-breakpoint-up(sm) {
        @include body-3-desktop;
    }
}

.body1link {
    @include link;
    @include bold-weight;
    @include body-1-mobile;

    @include media-breakpoint-up(sm) {
        @include body-1-desktop;
    }
}

.body2link {
    @include link;
    @include bold-weight;
    @include body-2-mobile;

    @include media-breakpoint-up(sm) {
        @include body-2-desktop;
    }
}

.body3link {
    @include link;
    @include bold-weight;
    @include body-3-mobile;

    @include media-breakpoint-up(sm) {
        @include body-3-desktop;
    }
}

// Subtitle

@mixin subtitle {
    @include font-family-run-wild;
    @include regular-weight;
    @include uppercase;
}

@mixin subtitle-1-mobile {
    @include subtitle;

    font-size: 44px;
    line-height: 120%;
    letter-spacing: -0.48px;

}

@mixin subtitle-1-desktop {
    @include subtitle;

    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.6px;
}

@mixin subtitle-2-mobile {
    @include subtitle;

    font-size: 24px;
    line-height: 120%;
}

@mixin subtitle-2-desktop {
    @include subtitle;

    font-size: 30px;
    line-height: 120%;
}

@mixin subtitle-3-mobile {
    @include subtitle;

    font-size: 12px;
    line-height: 110%;
}

@mixin subtitle-3-desktop {
    @include subtitle;

    font-size: 14px;
    line-height: 110%;
}

.subtitle1 {
    @include subtitle-1-mobile;

    @include media-breakpoint-up(sm) {
        @include subtitle-1-desktop;
    }
}

.subtitle2 {
    @include subtitle-2-mobile;

    @include media-breakpoint-up(sm) {
        @include subtitle-2-desktop;
    }
}

.subtitle3 {
    @include subtitle-3-mobile;

    @include media-breakpoint-up(sm) {
        @include subtitle-3-desktop;
    }
}


// Subheading

@mixin subheading {
    @include font-family-run-wild;
    @include regular-weight;
    text-transform: none;
}

@mixin subheading-1-mobile {
    @include subheading;

    font-size: 44px;
    line-height: 120%;
    letter-spacing: -0.48px;
}

@mixin subheading-1-desktop {
    @include subheading;

    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.6px;
}

@mixin subheading-2-mobile {
    @include subheading;

    font-size: 24px;
    line-height: 120%;
}

@mixin subheading-2-desktop {
    @include subheading;

    font-size: 36px;
    line-height: 120%;
}

@mixin subheading-3-mobile {
    @include subheading;

    font-size: 24px;
    line-height: 120%;
}

@mixin subheading-3-desktop {
    @include subheading;

    font-size: 32px;
    line-height: 120%;
}

.subheading1 {
    @include subheading-1-mobile;

    @include media-breakpoint-up(sm) {
        @include subheading-1-desktop;
    }
}

.subheading2 {
    @include subheading-2-mobile;

    @include media-breakpoint-up(sm) {
        @include subheading-2-desktop;
    }
}

.subheading3 {
    @include subheading-3-mobile;

    @include media-breakpoint-up(sm) {
        @include subheading-3-desktop;
    }
}

// Utilities

.textAlignCenter {
    text-align: center;
}

.textAlignLeft {
    text-align: left;
}

.unselectable {
    user-select: none;
}

.uppercase {
    @include uppercase;
}