@import "src/styles/vars.scss";

$activatedColor: $color-tangerine;

.item {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin: 0 10px 0 0;
    text-align: center;

    svg {
        margin: 0;
    }
}

.itemIcon {
    align-items: center;
    background: $color-black-alpha-5;
    border-radius: 50%;
    display: flex;
    height: 80px;
    justify-content: center;
    margin-bottom: 12px;
    transition: background 300ms $easing-easeOutExpo;
    width: 80px;

    &:hover {
        background: $color-black-alpha-10;
    }

    @include media-breakpoint-down(sm) {
        height: 60px;
        width: 60px;

        svg {
            // todo: Figure out why viewBox doesn't get rendered for the SVG
            // icons, which prevents us from resizing the icon directly
            transform: scale(0.75);
        }
    }
}

.itemIconIdle {
    background: $color-black-alpha-5;
}

.itemIconActive {
    background: $activatedColor !important;

    svg {
        opacity: 1;
    }

    &:hover {
        background: $activatedColor !important;
    }
}

.itemTextIdle {
    color: $color-black;
}

.pillItem {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    margin-bottom: 4px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background-color: $color-oatmeal-tint;
    }

    &[data-category-active="true"] {
        background-color: $activatedColor;
    }
}

.pillItemText {
    color: $color-black;
}

.pillItemTextIdle {
    color: $color-black-alpha-60;
}