$bannerHeight: 48px;
$drawerWidth: 300px;
$headerHeight: 72px;
$searchBarHeight: 48px;

$margin-values: (
  0: 0px,
  5: 5px,
  10: 10px,
  16: 16px,
  20: 20px,
  30: 30px,
  40: 40px,
  60: 60px,
  100: 100px
);

$padding-values: (
  4: 4px,
  20: 20px,
  40: 40px,
  60: 60px
);

@each $mb-value, $mb-size in $margin-values {
  .mb#{$mb-value} {
    margin-bottom: $mb-size;
  }
}

@each $mt-value, $mt-size in $margin-values {
  .mt#{$mt-value} {
    margin-top: $mt-size;
  }
}

@each $mb-value, $mb-size in $margin-values {
  .mx#{$mb-value} {
    margin-left: $mb-size;
    margin-Right: $mb-size;
  }
}

@each $my-value, $my-size in $margin-values {
  .my#{$my-value} {
    margin-top: $my-size;
    margin-bottom: $my-size;
  }
}

@each $p-value, $p-size in $padding-values {
  .pt#{$p-value} {
    padding-top: $p-size;
  }
}

@each $p-value, $p-size in $padding-values {
  .px#{$p-value} {
    padding-right: $p-size;
    padding-left: $p-size;
  }
}

.column {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  .alignLeft {
    align-items: flex-start;
  }

  .alignRight {
    align-items: flex-end;
  }
}

.gap10 {
  gap: 10px;
}

.height100percent {
  height: 100%;
}

.height100vh {
  height: 100vh;
  height: 100dvh;
}

.heightFullScreen {
  min-height: 100vh;
  min-height: 100dvh;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.maxWidth400px {
  max-width: 400px;
}

.maxWidth600px {
  max-width: 600px;
}

.positionFixed {
  position: fixed;
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.row {
  display: flex;
  gap: 20px;
  width: 100%
}

.width50percent {
  width: 50%;
}

.width100percent {
  width: 100%;
}

.width100vw {
  width: 100vw;
}


:export {
  bannerHeight: $bannerHeight;
  drawerWidth: $drawerWidth;
  headerHeight: $headerHeight;
}