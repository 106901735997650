@import './colors.module.scss';

$onboarding-header-height: 140px;
$onboarding-footer-height-desktop: 100px;
$onboarding-footer-height-mobile: 50px;
$onboarding-max-width: 440px;
$minimal-header-height: 57px;

.discountText {
    color: #a85822;
    font-weight: bold;
}

.discountTextContainer {
    gap: 16px;
    max-width: $onboarding-max-width;
    padding-bottom: 20px;
    text-align: center;
    width: 100%;
}

.maxWidth {
    max-width: $onboarding-max-width;
    width: 100%;
}

.noHeader {
    padding-top: 40px;
}

.subtitlePlain {
    font-size: 20px;
    font-weight: 700;
}

.withGutter {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.withFooter {
    padding-bottom: $onboarding-footer-height-desktop;

    @media (max-width: $onboarding-max-width) {
        padding-bottom: $onboarding-footer-height-mobile;
    }
}

.withHeader {
    padding-top: calc($onboarding-header-height);
}

.withMinimalHeader {
    padding-top: $minimal-header-height;
}

// NEW STYLES FOR CM_EXPERIMENT

.stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    min-height: 100dvh;
}

.stepContainerScrollableContent {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    &.alignItemsCenter {
        align-items: center;
    }
    
}

:export {
    ONBOARDING_HEADER_HEIGHT: $onboarding-header-height;
    ONBOARDING_FOOTER_HEIGHT_DESKTOP: $onboarding-footer-height-desktop;
    ONBOARDING_FOOTER_HEIGHT_MOBILE: $onboarding-footer-height-mobile;
    ONBOARDING_MAX_WIDTH: $onboarding-max-width;
}
