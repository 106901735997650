@import "src/styles/colors.module.scss";

$max-cue-width: 400px;

.container {
  background: $color-salt;
  border-radius: 8px;
  box-shadow: 0px 4px 12px -1px $color-black-20;
  display: block;
  padding: 16px 12px;
  text-align: center;
}

.containerStatic {
  width: 100%;
}

.containerAnchor {
  max-width: $max-cue-width;
  position: absolute;
  z-index: 100;

  @media (max-width: ($max-cue-width + (12 * 2px))) {
    left: 12px;
    right: 12px;
    max-width: unset;
  }
}

.containerAnchor::before {
  content: "";
  display: block;
  width: var(--caret-size);
  height: var(--caret-size);
  background: $color-salt;
  position: absolute;
  left: var(--caret-offset);
  top: calc((var(--caret-size) / 2) * -1);
  transform: rotate(-45deg);
}

.button {
  background: $color-black;
  border-radius: 8px;
  height: 36px;
  min-width: 80px;
}

.buttonLabel {
  color: $color-vanilla;
}
