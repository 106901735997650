@import 'src/styles/vars';
@import './colors.module.scss';

.alignCenter {
    text-align: center;
}

.fontWeight600 {
    font-weight: 600;
}

.handWrittenText {
    font-family: 'Run Wild', serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;

    &.small {
        font-size: 1.5rem;
    }

    &.large {
        font-size: 3.0rem;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    @media (max-width: $onboarding-max-width) {
        font-size: 2.0rem;
    }
}

.handWrittenUnderscore {
    background-repeat: no-repeat;
    background-position-y: 48px;
    background-image: url(/images/onboarding-new/text-underscore-uptick.svg);
}

.bodyText {
    color: $color-black;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
}

.linkText {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.16px;
    text-decoration-line: underline;
    text-transform: capitalize;
}

.clickableText {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}

.informationalText {
    text-align: center;

    a {
        color: $color-black;
    }
}

.lineItem {
    font-size: 14px;
    font-weight: 700;
}

.sectionHeader {
    color: $color-black-alpha-60;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.upperCase {
    text-transform: uppercase;
}
