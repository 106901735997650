@import "src/styles/colors.module.scss";
@import 'src/styles/vars';

$containerHeight: 72px;

:export {
    containerHeight: $containerHeight;
}

.container {
    align-items: center;
    display: flex;
    height: $containerHeight;
    justify-content: center;
    width: 100%;
    background-color: $color-oatmeal;


    .menuButtonContainer {
        align-items: center;
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: flex-start;
        left: 16px;
        max-width: 100px;
        position: relative;
        width: 100px;
    }

    .menuButton {
        position: relative;
    }

    .menuButtonUnread:after {
        background: $color-blueberry;
        border-radius: 50%;
        content: "";
        display: block;
        position: absolute;
        right: -4px;
        top: 0;
        height: 10px;
        width: 10px;
    }

    .pageTitleContainer {
        display: flex;
        flex: 1;
        justify-content: center;

        .pageTitle {
            color: $color-black;
        }
    }

    .refillButtonContainer {
        align-items: center;
        display: flex;
        flex: 0.5;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 16px;
        max-width: 100px;
        width: 100px;
    }
}

.hidden {
    overflow: hidden;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function, visibility 0s $mobile-header-collapse-transition-time;
}

.visible {
    opacity: 1;
    height: $containerHeight;
    visibility: visible;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function;
}
