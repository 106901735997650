@import "src/styles/vars";

.container {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-weight: 600;
    // padding: 10px 10px 10px 10px;
    justify-content: center;
    width: 100%;

    span {
        padding-top: 4px;
        font-size: 12px;
    }

    &.block {
        &.error {
            background-color: $color-peach-tint;
        }
    
        &.success {
            background-color: $color-matcha-tint;
        }

        &.info {
            background-color: $color-salt;
        }

        &.warning {
            background-color: $color-oatmeal-tint;
        }
    }


    &.toast {
        &.error {
            background-color: $color-peach;
        }
    
        &.success {
            background-color: $color-matcha;
        }

        &.successLight {
            background-color: $color-vanilla;
        }

        &.info {
            background-color: $color-salt;
        }
        
        &.warning {
            background-color: $color-vanilla;
        }
    }
}

.content {
    width: 100%;
    padding: 10px 0 ;
}

.margin {
    width: 60px;
    display: flex;
    justify-content: center;
    min-width: 20px;
    height: 20px;
}
