@import '../../../../../styles/vars';

.button {
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    height: $onboarding-footer-height-mobile;
    justify-content: center;
    text-transform: uppercase;
    max-width: $onboarding-max-width;
    outline: none;
    width: 100%;

    &:disabled {
        cursor: default;
    }

    &.tangerine {
        background-color: $color-tangerine;
        color: $color-black;

        &:hover {
            background-color: $color-tangerine-shade;
            color: $color-black;
        }

        &:focus {
            background-color: $color-tangerine-shade;
            border: 1px solid $color-black;
            color: $color-black;
        }

        &:disabled {
            background-color: $color-black-alpha-10;
            color: $color-black-alpha-30;
        }

        &.loading {
            background-color: $color-tangerine-tint;
            color: $color-black-alpha-50;
        }
    }

    &.charcoal {
        background-color: $color-black;
        color: $color-oatmeal;

        &:hover {
            background-color: $color-black-alpha-70;
            color: $color-oatmeal;
        }

        &:focus {
            background-color: $color-black-alpha-80;
            color: $color-oatmeal;
        }

        &:disabled {
            background-color: $color-black-alpha-10;
            color: $color-black-alpha-30;
        }

        &.loading {
            background-color: $color-black-alpha-50;
            color: $color-black-alpha-50;
        }
    }

    @media (min-width:calc($onboarding-max-width + 1px)) {
        border-radius: 4px;
    }
}

.container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}