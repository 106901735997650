@import 'src/styles/vars';
@import 'src/styles/typography.module.scss';

$containerHeight: 48px;

:export {
    containerHeight: $containerHeight;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: $containerHeight;
    background-color: $color-vanilla;
    width: 100%;
    padding: 0 16px;
    cursor: pointer;
}

.text {
    @extend .body2regular;
}

.hidden {
    overflow: hidden;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function, visibility 0s $mobile-header-collapse-transition-time;
}

.visible {
    opacity: 1;
    height: $containerHeight;
    visibility: visible;
    transition: height $mobile-header-collapse-transition-time $mobile-header-easing-function, opacity $mobile-header-collapse-transition-time $mobile-header-easing-function;
}
