@import 'src/styles/vars';

$scrollItemGap: 2px;
$scrollItemGapCompact: 4px;
$gradientWidth: 16px;

@mixin hideArrowButton {
    box-shadow: unset;
    height: 100%;
    width: $gradientWidth;
    border-radius: unset;

    svg {
        display: none;
    }
}

@mixin leftGradient {
    background: none;
    left: -10px;
}

@mixin rightGradient {
    background: none;
    left: -10px;
}

.container {
    &.fullWidth {
        :global {
            .react-horizontal-scrolling-menu--scroll-container {
                width: 100%;
            }
        }
    }

    &.fullBleed {
        :global {
            .react-horizontal-scrolling-menu--item {
                &:first-child {
                    margin-left: 20px;
                }

                &:last-child {
                    margin-right: 20px;
                }
            }
        }
    }

    :global {
        .react-horizontal-scrolling-menu--scroll-container {

            overflow: auto;
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: $scrollItemGap;
        }

        @include media-breakpoint-down(xs) {
            .react-horizontal-scrolling-menu--scroll-container {
                grid-column-gap: $scrollItemGapCompact;
            }
        }

        .react-horizontal-scrolling-menu--inner-wrapper {
            display: flex;
        }

        .react-horizontal-scrolling-menu--arrow-left,
        .react-horizontal-scrolling-menu--arrow-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 0;
            z-index: 2;
        }

        .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
            display: none;
        }

        .react-horizontal-scrolling-menu--scroll-container {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }

        .react-horizontal-scrolling-menu--item {
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .scrollButton {
        position: relative;
        width: 39px;
        height: 39px;
        background: $color-vanilla;
        border-radius: 50%;
        border: 0 solid;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);

        // move the button to the right to overlay on the left edge of the scroll container
        &.rightArrowButton {
            left: -16px;
        }

        // move the button to the left to overlay on the right edge of the scroll container
        &.leftArrowButton {
            right: 16px;
        }

        @include media-breakpoint-down(xs) {

            // move the button to the right to overlay on the left edge of the scroll container
            &.rightArrowButton {
                left: -46px;
            }

            // move the button to the left to overlay on the right edge of the scroll container
            &.leftArrowButton {
                right: -8px;
            }
        }

        &:disabled {
            display: none;
        }
    }

    // reuse the same styles for both viewport
    @mixin hiddenArrowButtonStyles {
        @include hideArrowButton;

        &.rightArrowButton {
            @include leftGradient;
        }

        &.leftArrowButton {
            @include rightGradient;
        }
    }

    &.disableArrowsOnMobile {
        .scrollButton {
            @include media-breakpoint-down(xs) {
                @include hiddenArrowButtonStyles;
            }
        }
    }

    &.disableArrowsOnDesktop {
        .scrollButton {
            @include media-breakpoint-up(xs) {
                @include hiddenArrowButtonStyles;
            }
        }
    }

}