@import '_vars.scss';
@import 'mixins/accessibility';
@import 'mixins/bootstrap';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@import '~flatpickr/dist/flatpickr.css';

@font-face {
    font-family: 'Run Wild';
    src: local('Run Wild'),
        url('/fonts/RunWild.woff2') format('woff2'),
        url('/fonts/RunWild.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/**
 * @license
 * MyFonts Webfont Build ID 4098477, 2021-06-04T16:43:21-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaNeueLTStd-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-55-roman-189171/
 *
 * Webfont: HelveticaNeueLTStd-Bd by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-75-bold-189171/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/

// Necessary for Helvetica Neue licensing
@import url("//hello.myfonts.net/count/3e89ad");
@import './colors.module.scss';

:root {
    --color-cayenne-shade: #{$color-cayenne-shade};
    --color-cayenne-tint: #{$color-cayenne-tint};
    --color-matcha: #{$color-matcha};
    --color-salt: #{$color-salt};
    --color-black: #{$color-black};
    --color-black-5: #{$color-black-5};
    --color-black-60: #{$color-black-60};

    --easing-easeOutCubic: #{$easing-easeOutCubic};
}

a {
    color: #000;
    text-decoration: none;

    &:hover {
        color: #000;
        text-decoration: none;
    }
}

// Prevent body scroll when a dialog is open
html:has(dialog[open]) {
    overflow-y: hidden;
}

body {
    background-color: $color-oatmeal;
    color: #000;
    font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
    overscroll-behavior: none;
}

// iOS Safari input radius workaround
// https://stackoverflow.com/questions/5263260/stop-safari-mobile-from-giving-input-buttons-rounded-corners
input, textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

// Flatpickr alt inputs are readonly but interactive through the picker
// so we need to override Bootstrap's readonly styling there
.flatpickr-input + .form-control[readonly] {
    background-color: transparent;
}

// Underline links in paragraphs to distinguish them
p a, .btn-link {
    text-decoration: underline;
}

.visually-hidden {
    @include visually-hidden();
}

.popover {
    @include styled-popover();
}
