@mixin styled-popover {
  background-color: $color-oatmeal;
  box-shadow: 0 0 8px 0 $color-black-alpha-20;
  border: none;
  border-radius: 5px;
  max-width: 320px;

  @include media-breakpoint-up(sm) {
    max-width: 420px;
  }

  & > .popover-body {
    padding: 16px;
    text-align: center;
  }

  &.bs-popover-bottom {
    .arrow {
        &::after {
          border-bottom-color: $color-oatmeal;
        }
      }
  }

    &.bs-popover-top {
      .arrow {
        &::after {
          border-top-color: $color-oatmeal;
        }
      }
    }
}