@import "src/styles/colors.module.scss";

.button {
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: center;
  position: relative;
  width: 56px;
}

.buttonUnread:after {
  background: $color-blueberry;
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  right: 6px;
  top: 6px;
  height: 10px;
  width: 10px;
}
