$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

@mixin font-family-helvetica-neue {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin font-family-run-wild {
    font-family: Run Wild, serif;
}

// Headlines
@mixin headline-1-desktop {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Headline 1 */
    @include font-family-helvetica-neue;
    font-size: 60px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 100%;
    letter-spacing: -2px;
    text-transform: uppercase;
}

@mixin headline-1-mobile {
    /* Mobile/Headline 1 */
    @include font-family-helvetica-neue;
    font-size: 35px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 100%;
    letter-spacing: -0.35px;
    text-transform: uppercase;
}


@mixin headline-2-desktop {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Headline 2 */
    @include font-family-run-wild;
    font-size: 60px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 110%; /* 66px */
}

@mixin headline-2-mobile {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Mobile/Headline 2 */
    @include font-family-run-wild;
    font-size: 45px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 120%; /* 54px */
}

@mixin headline-3-desktop {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Headline 3 */
    @include font-family-helvetica-neue;
    font-size: 40px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 100%; /* 40px */
    text-transform: uppercase;
}

@mixin headline-3-mobile {

    /* Mobile/Headline 3 */
    @include font-family-helvetica-neue;
    font-size: 25px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 100%; /* 25px */
    text-transform: uppercase;
}

@mixin headline-4-desktop {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Headline 4 */
    @include font-family-helvetica-neue;
    font-size: 30px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 110%; /* 33px */
}

@mixin headline-4-mobile {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Mobile/Headline 4 */
    @include font-family-helvetica-neue;
    font-size: 20px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 120%; /* 24px */
}

@mixin headline-5-desktop {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Headline 5 */
    @include font-family-run-wild;
    font-size: 35px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 120%; /* 42px */
}
@mixin headline-5-mobile {

    /* Mobile/Headline 5 */
    @include font-family-run-wild;
    font-size: 25px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 120%; /* 36px */
}
@mixin headline-6-desktop {

    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Headline 6 */
    @include font-family-helvetica-neue;
    font-size: 25px;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 110%; /* 27.5px */
}
@mixin headline-6-mobile {
    /* Mobile/Headline 6 */
    @include font-family-helvetica-neue;
    font-size: 18px;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 130%; /* 23.4px */
}

//Subtitles
@mixin subtitle-1-desktop {
    /* Desktop/Subtitle 1 */
    @include font-family-helvetica-neue;
    font-size: 18px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 160%; /* 28.8px */
    text-transform: uppercase;
}

@mixin subtitle-1-mobile {
    @include subtitle-1-desktop;
}

@mixin subtitle-2-desktop {
    /* Desktop/Subtitle 2 */
    @include font-family-helvetica-neue;
    font-size: 14px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 100%; /* 14px */
    text-transform: uppercase;
}

@mixin subtitle-2-mobile {
    @include subtitle-2-desktop;
}

@mixin subtitle-3-desktop {
    /* Desktop/Subtitle 3 */
    @include font-family-run-wild;
    font-size: 14px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 110%; /* 15.4px */
    text-transform: uppercase;
}

@mixin subtitle-3-mobile {
    @include subtitle-3-desktop;
}

@mixin body-1-default {
    @include font-family-helvetica-neue;
    font-size: 18px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 140%; /* 25.2px */
}
@mixin body-1-reg-desktop {
    @include body-1-default;
    font-feature-settings: 'clig' off, 'liga' off;
}
@mixin body-1-reg-mobile {
    @include body-1-default;
    font-size: 16px;
}
@mixin body-1-med-desktop {
    @include body-1-reg-desktop;
    font-weight: $font-weight-medium;
}
@mixin body-1-med-mobile {
    @include body-1-reg-mobile;
    font-weight: $font-weight-medium;

}
@mixin body-1-bold-desktop {
    @include body-1-reg-desktop;
    font-weight: $font-weight-bold;
}
@mixin body-1-bold-mobile {
    @include body-1-reg-mobile;
    font-weight: $font-weight-bold;
}

@mixin body-2-default {
    @include font-family-helvetica-neue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}
@mixin body-2-reg-desktop {
    @include body-2-default;
    font-feature-settings: 'clig' off, 'liga' off;
}
@mixin body-2-reg-mobile {
    @include body-2-default;
    font-size: 14px;
}
@mixin body-2-med-desktop {
    @include body-2-reg-desktop;
    font-weight: $font-weight-medium;
}
@mixin body-2-med-mobile {
    @include body-2-reg-mobile;
    font-weight: $font-weight-medium;

}
@mixin body-2-bold-desktop {
    @include body-2-reg-desktop;
    font-weight: $font-weight-bold;
}
@mixin body-2-bold-mobile {
    @include body-2-reg-mobile;
    font-weight: $font-weight-bold;
}



@mixin body-3-default {
    @include font-family-helvetica-neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}
@mixin body-3-reg-desktop {
    @include body-3-default;
    font-feature-settings: 'clig' off, 'liga' off;
}
@mixin body-3-reg-mobile {
    @include body-3-default;
    font-size: 12px;
}
@mixin body-3-med-desktop {
    @include body-3-reg-desktop;
    font-weight: $font-weight-medium;
}
@mixin body-3-med-mobile {
    @include body-3-reg-mobile;
    font-weight: $font-weight-medium;

}
@mixin body-3-bold-desktop {
    @include body-3-reg-desktop;
    font-weight: $font-weight-bold;
}
@mixin body-3-bold-mobile {
    @include body-3-reg-mobile;
    font-weight: $font-weight-bold;
}


@mixin button-text-desktop {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Button */
    @include font-family-helvetica-neue;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 100%; /* 16px */
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
@mixin button-text-mobile {
    @include button-text-desktop;
}
@mixin link-text-1-desktop {
    font-feature-settings: 'clig' off, 'liga' off;

    /* Desktop/Link 1 */
    @include font-family-helvetica-neue;
    font-size: 18px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 140%; /* 25.2px */
    text-decoration-line: underline;
}


@mixin link-text-1-mobile {
    @include link-text-1-desktop;
    font-size: 16px;
    letter-spacing: -0.16px;
}

@mixin link-text-2-desktop {
    @include font-family-helvetica-neue;
    font-size: 14px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
    text-decoration-line: underline;
}


@mixin link-text-2-mobile {
    @include link-text-2-desktop;
    font-size: 12px;
    letter-spacing: -0.12px;
}

