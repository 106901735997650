@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import 'src/styles/colors.module.scss';
@import 'src/styles/typography.module.scss';

.geosuggest {
    display: inline-block;
    position: relative;
    width: inherit;
}

.geosuggest__input {
    border: 1.5px solid $color-black-alpha-40;
    border-radius: 3px;
    box-shadow: 0 0 0px 1000px $color-oatmeal inset;
    color: $color-black-alpha-60;
    height: 45px;
    outline: none;
    padding: 10px;
    width: 100%;

    @include body-1-mobile;
    @include regular-weight;

    @include media-breakpoint-up(sm) {
        @include body-1-desktop;
    }

    &.error {
        border: 1.5px solid $color-peach;
    }

    &:focus,
    &.focused {
        border: 1.5px solid $color-black;
        color: $color-black;
    }

    & input.hiddenField {
        background: $color-oatmeal;
        border: none;
        box-shadow: 0 0 0px 1000px $color-oatmeal inset;
        outline: none;
    }
}

.geosuggest__suggests {
    background: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0px 2px 2px 0px #999999;
    left: 50%;
    list-style: none;
    margin-top: -10px;
    max-height: 25em;
    min-width: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 55px;
    transform: translateX(-50%);
    width: 100%;
    z-index: 5;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item {
    @include body-1-mobile;
    @include regular-weight;

    @include media-breakpoint-up(sm) {
        @include body-1-desktop;
    }

    background: $color-oatmeal;
    color: $color-black-alpha-60;
    cursor: pointer;
    padding: 0.5em 0.65em;
    white-space: nowrap;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
    color: $color-black-60;
    background: $color-black-10;
}

.geosuggest__item--active,
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
    background: $color-black-10;
    color: $color-black;
}

.geosuggest__item__matched-text {
    font-weight: bold;
}